<template>
<div class="flex h-screen bg-gradient-to-br from-gray-50 to-gray-100 overflow-hidden">
    <!-- Logo and text at the top left -->
    <div class="absolute top-8 left-8 flex items-center z-10">
        <img src="https://api.refreps.com/images/refreps_logo_web.png" alt="Logo" class="w-12 h-auto filter drop-shadow-lg" />
        <!-- <span class="ml-2 text-xl font-semibold text-black">RefReps</span> -->
    </div>

    <!-- Left panel - Login form -->
    <div class="w-full md:w-2/5 flex flex-col justify-center p-8 bg-white relative shadow-2xl transition-transform duration-200 ease-in-out" :style="{ transform: `translateX(${leftPanelX})` }" style="background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3), 0 0 20px 0px rgba(0, 200, 200, 0.2);">
        <div class="w-10/12 max-w-xl mx-auto">
            <login-form :resetUserData="resetUserData" :didResetPassword="didResetPassword" :handleLogin="handleLogin" :isLoading="isLoading" :errorMessage="errorMessage" :emailWarning="emailWarning" :passwordWarning="passwordWarning" @switch-to-register="switchToRegister" @switch-to-forgot-start="switchToForgotStart" @reset-errors="resetLoginErrors" @show-forgot-start-form="handleForgotPassword" key="login" ref="loginFormRef"></login-form>
        </div>
    </div>

    <!-- Right panel - Redesigned showcases with emphasis on image -->
    <div class="hidden md:block md:w-3/5 relative overflow-hidden transition-transform duration-200 ease-in-out" :style="{ transform: `translateX(${rightPanelX})` }">
        <!-- Dynamic background with parallax effect -->
        <div class="absolute inset-0 bg-cover bg-center transform scale-105 transition-all duration-1000 ease-out" :style="{
          backgroundImage: `url('${showcases[currentShowcaseIndex].backgroundImage}')`,
          transform: `scale(${1 + currentShowcaseIndex * 0.2})`
        }"></div>

        <!-- Overlay with modern gradient and interactive elements -->
        <div class="absolute inset-0 bg-gradient-to-br from-teal-900/80 via-teal-400/60 to-teal-600/60 flex flex-col justify-center p-8 backdrop-blur-sm">
            <div class="absolute inset-0">
                <div class="absolute inset-0 bg-gradient-to-br from-teal-900/80 via-teal-400/60 to-teal-600/60"></div>
                <div class="absolute inset-0 bg-[url('https://d3sjyulja6yeep.cloudfront.net/files/bg_zoom.jpeg')] bg-cover bg-center mix-blend-multiply opacity-[0.5]"></div>
            </div>
            <div class="flex flex-col items-center justify-center h-full relative z-10">
                <!-- Image section -->
                <transition name="fade" mode="out-in">
                    <div :key="currentShowcaseIndex" class="flex items-center justify-center w-full mb-8">
                        <img :src="showcases[currentShowcaseIndex].image" alt="Course Image" class="w-full object-contain rounded-lg shadow-2xl animate__animated animate__fadeIn" />
                    </div>
                </transition>

                <!-- Text section -->
                <div class="w-3/4 text-white text-center">
                    <h2 class="text-4xl font-bold mb-2 leading-tight">
                        Helping the world see the game through the eyes of the officials!
                    </h2>
                </div>
            </div>
        </div>

        <!-- Navigation dots -->
        <div class="absolute bottom-[40px] left-1/2 transform -translate-x-1/2 flex space-x-4">
            <button v-for="(_, index) in showcases" :key="index" @click="currentShowcaseIndex = index" class="w-3 h-3 rounded-full transition-all duration-300 ease-in-out focus:outline-none" :class="currentShowcaseIndex === index 
                    ? 'bg-white scale-125 shadow-lg' 
                    : 'bg-white/40 hover:bg-white/60'" :aria-label="`Go to showcase ${index + 1}`"></button>
        </div>
    </div>

    <!-- Full-screen register form -->
    <transition name="fade-slow" appear>
        <div v-show="showRegisterForm" class="absolute inset-0 flex items-center bg-white justify-center">
            <div class="w-full bg-white">
                <register-form :orgID="orgID" @back-to-home="switchToLogin" @register-complete="handleRegisterComplete" key="register"></register-form>
            </div>
        </div>
    </transition>

    <!-- Full-screen forgot start view -->
    <transition name="fade-slow" appear>
        <div v-show="showForgotStartForm" class="absolute inset-0 flex items-center bg-white justify-center">
            <div class="w-full bg-white">
                <forgot-password @back-to-home="switchToLogin" @back-to-home-from-reset="handleBackFromReset" key="forgot-password"></forgot-password>
            </div>
        </div>
    </transition>

    <transition name="fade-slow" appear>
        <div v-show="showForceResetForm" class="absolute inset-0 flex items-center bg-white justify-center">
            <div class="w-full bg-white">
                <force-password-reset :userId="userId" key="force-reset"></force-password-reset>
            </div>
        </div>
    </transition>

    <transition name="fade-slow" appear>
        <div v-show="showMultipleOrgs" class="absolute inset-0 flex items-center bg-white justify-center">
            <div class="w-full bg-white">
                <choose-organization key="choose-organization"></choose-organization>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import {
    ref,
    computed,
    onMounted,
    onUnmounted,
    nextTick,
    watch,
    watchEffect,
} from 'vue'
import {
    useRouter,
    useRoute
} from 'vue-router'
import LoginForm from './LoginForm.vue'
import RegisterForm from '@/components/Login/Register/RegisterForm.vue'
import ForgotPassword from '@/components/Login/ForgotPassword/ForgotPassword.vue'
import ForcePasswordReset from '@/components/Login/ResetPassword/ForcePasswordReset.vue'
import ChooseOrganization from './ChooseOrganization.vue'
import useMainStore from "@/stores/main"
import axios from 'axios'
import {
    storeToRefs
} from 'pinia';
import * as Sentry from "@sentry/vue";

export default {
    props: {
        query: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        LoginForm,
        RegisterForm,
        ForgotPassword,
        ForcePasswordReset,
        ChooseOrganization
    },
    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const mainStore = useMainStore()
        const {
            isLoggedIn
        } = storeToRefs(mainStore);

        console.log('Component setup: isLoggedIn =', isLoggedIn.value);

        const currentShowcaseIndex = ref(0)
        const didResetPassword = ref(false)
        const resetUserData = ref({})
        const isLoading = ref(false)
        const errorMessage = ref('')
        const emailWarning = ref(false)
        const passwordWarning = ref(false)

        const isAnimating = ref(false)
        const leftPanelProgress = ref(0)
        const rightPanelProgress = ref(0)
        const showRegisterForm = ref(false)
        const showForgotStartForm = ref(false)
        const showForceResetForm = ref(false)
        const loginFormRef = ref(null)
        const registerFormRef = ref(null)
        const showWelcome = ref(false)
        const userId = ref('')
        const orgID = ref('')
        const showMultipleOrgs = ref(false)

        let rotationInterval

        const showcases = [{
                id: 1,
                backgroundImage: 'https://d3sjyulja6yeep.cloudfront.net/files/1.png',
                title: 'Full-Stack Development Mastery',
                instructor: 'Guided by Industry Veteran John Smith',
                image: 'https://d3sjyulja6yeep.cloudfront.net/files/1.png',
                description: 'Build robust, scalable applications from front to back. Master the latest technologies and frameworks used by top tech companies.'
            },
            {
                id: 2,
                backgroundImage: 'https://d3sjyulja6yeep.cloudfront.net/files/2.png',
                title: 'Full-Stack Development Mastery',
                instructor: 'Guided by Industry Veteran John Smith',
                image: 'https://d3sjyulja6yeep.cloudfront.net/files/2.png',
                description: 'Build robust, scalable applications from front to back. Master the latest technologies and frameworks used by top tech companies.'
            },
            {
                id: 3,
                backgroundImage: 'https://d3sjyulja6yeep.cloudfront.net/files/3.png',
                title: 'Full-Stack Development Mastery',
                instructor: 'Guided by Industry Veteran John Smith',
                image: 'https://d3sjyulja6yeep.cloudfront.net/files/3.png',
                description: 'Build robust, scalable applications from front to back. Master the latest technologies and frameworks used by top tech companies.'
            },
        ]

        const leftPanelX = computed(() => `${leftPanelProgress.value}%`)
        const rightPanelX = computed(() => `${rightPanelProgress.value}%`)

        const rotateShowcase = () => {
            currentShowcaseIndex.value = (currentShowcaseIndex.value + 1) % showcases.length
        }

        const animate = (start, end, duration, callback) => {
            const startTime = Date.now()
            const tick = () => {
                const elapsed = Date.now() - startTime
                const progress = Math.min(elapsed / duration, 1)
                const value = start + (end - start) * progress
                callback(value)
                if (progress < 1) {
                    requestAnimationFrame(tick)
                }
            }
            tick()
        }

        const handleLogin = async (loginData) => {

            isLoading.value = true
            errorMessage.value = ''
            emailWarning.value = false
            passwordWarning.value = false

            try {
                const response = await axios({
                    method: 'POST',
                    withCredentials: true,
                    credentials: 'include',
                    url: `${import.meta.env.VITE_BASE_LINK}${loginData.developerMode ? '/login-developer' : '/login'}`,
                    data: {
                        email: loginData.email,
                        password: loginData.password
                    }
                })

                if (response.data.code === 200) {
                    mainStore.isUserInstructor = response.data.isUserInstructor
                    mainStore.isLoggedIn = true
                    mainStore.user = response.data.user
                    mainStore.organization = response.data.organization
                    mainStore.orgData = response.data.organization
                    mainStore.organizations = response.data.organizations
                    mainStore.currentOrganization = response.data.currentOrganization
                    mainStore.userExams = response.data.userExams

                    // Set developer mode flags if logging in as developer
                    if (loginData.developerMode) {
                        mainStore.isDevLoggedIn = true
                        mainStore.isInDevMode = true
                    }
                    // log out developer mode
                    console.log('loginData.developerMode', loginData.developerMode)
                    console.log('mainStore.isDevLoggedIn', mainStore.isDevLoggedIn)
                    console.log('mainStore.isInDevMode', mainStore.isInDevMode)

                    if (response.data.user.needsPasswordReset) {
                        userId.value = response.data.user.id
                        switchToForceResetForm()
                        return
                    }

                    if (response.data.isUserInstructor) {

                        if (response.data.organizations.length > 1) {
                            switchToChooseMultipleOrgs()
                            return
                        }

                        mainStore.isUserInstructor = true
                        Sentry.setUser({
                            id: response.data.user.id,
                            email: response.data.user.email,
                            name: response.data.user.name,
                            isUserInstructor: true
                        })
                        router.push({
                            name: "organization",
                            params: {
                                id: response.data.organization.companyID || response.data.organization.companyID,
                            }
                        })
                    } else {
                        mainStore.isUserInstructor = false
                        Sentry.setUser({
                            id: response.data.user.id,
                            email: response.data.user.email,
                            name: response.data.user.name,
                            isUserInstructor: false
                        })
                        router.push({
                            name: "dashboard"
                        })
                    }

                    mainStore.showLoginType = 'login'
                } else {
                    mainStore.isLoggedIn = false
                    errorMessage.value = response.data.message
                    Sentry.captureException(response.data.message);
                }
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000))
                console.log(error.response)
                if (error.response) {
                    if (error.response.status === 404) {
                        errorMessage.value = "User not found with that email."
                        emailWarning.value = true
                    } else if (error.response.status === 401) {
                        errorMessage.value = "Incorrect password."
                        passwordWarning.value = true
                    } else {
                        errorMessage.value = error.response.data.message || "An error occurred. Please try again."
                    }
                } else {
                    errorMessage.value = "An error occurred. Please try again."
                }
                console.error(error)
            } finally {
                isLoading.value = false
            }
        }

        const switchToChooseMultipleOrgs = async () => {
            if (isAnimating.value) return
            isAnimating.value = true

            if (loginFormRef.value) {
                loginFormRef.value.$emit('reset-errors')
            }

            animate(0, -100, 100, (value) => {
                leftPanelProgress.value = value
            })

            animate(0, 100, 100, (value) => {
                rightPanelProgress.value = value
            })

            setTimeout(async () => {
                showMultipleOrgs.value = true
                await nextTick();
                setTimeout(() => {
                    isAnimating.value = false
                }, 200)
            }, 200)
        }

        const switchToRegister = async () => {
            if (isAnimating.value) return
            isAnimating.value = true

            if (loginFormRef.value) {
                loginFormRef.value.$emit('reset-errors')
            }

            animate(0, -100, 100, (value) => {
                leftPanelProgress.value = value
            })

            animate(0, 100, 100, (value) => {
                rightPanelProgress.value = value
            })

            setTimeout(async () => {
                showRegisterForm.value = true
                await nextTick();
                setTimeout(() => {
                    isAnimating.value = false
                }, 200)
            }, 200)
        }

        const switchToForgotStart = async () => {
            if (isAnimating.value) return
            isAnimating.value = true

            if (loginFormRef.value) {
                loginFormRef.value.$emit('reset-errors')
            }

            animate(0, -100, 100, (value) => {
                leftPanelProgress.value = value
            })

            animate(0, 100, 100, (value) => {
                rightPanelProgress.value = value
            })

            setTimeout(async () => {
                showForgotStartForm.value = true
                await nextTick();
                setTimeout(() => {
                    isAnimating.value = false
                }, 200)
            }, 200)
        }

        const switchToForceResetForm = async () => {
            if (isAnimating.value) return
            isAnimating.value = true

            if (loginFormRef.value) {
                loginFormRef.value.$emit('reset-errors')
            }

            animate(0, -100, 100, (value) => {
                leftPanelProgress.value = value
            })

            animate(0, 100, 100, (value) => {
                rightPanelProgress.value = value
            })

            setTimeout(async () => {
                showForceResetForm.value = true
                await nextTick();
                setTimeout(() => {
                    isAnimating.value = false
                }, 200)
            }, 200)
        }

        const switchToLogin = () => {
            if (isAnimating.value) return
            isAnimating.value = true

            showRegisterForm.value = false
            showForgotStartForm.value = false
            showWelcome.value = false

            setTimeout(() => {
                animate(-100, 0, 100, (value) => {
                    leftPanelProgress.value = value
                })
                animate(100, 0, 100, (value) => {
                    rightPanelProgress.value = value
                })
                setTimeout(() => {
                    isAnimating.value = false
                }, 100)
            }, 250)
        }

        const handleRegisterComplete = async (userData) => {
            handleLogin(userData)
        };

        const handleForgotPassword = () => {
            switchToForgotStart();
        };

        const resetLoginErrors = () => {
            errorMessage.value = ''
            emailWarning.value = false
            passwordWarning.value = false
        }

        const handleBackFromReset = (userData) => {
            console.log(userData)
            handleLogin(userData)
        }

        //handle url parameters from url
        const handleUrlParameters = async () => {
            const urlParams = new URLSearchParams(window.location.search)
            const id = urlParams.get('id')
            const method = urlParams.get('method')
            console.log('URL parameters:', method, id)

            if (method === 'forceReset') {
                await nextTick()
                userId.value = id
                await switchToForceResetForm()
            }

            if (method && id) {
                orgID.value = id
                console.log('orgID set to:', orgID.value)
                await nextTick()
                // Remove parameters from URL
                await router.replace({
                    query: {}
                })

                if (method === 'register') {
                    await nextTick()
                    showRegisterForm.value = true
                }

            }
        }

        onMounted(() => {
            console.log('mainStore.isLoggedIn')
            console.log(mainStore.isLoggedIn)
            rotationInterval = setInterval(rotateShowcase, 8000)
            handleUrlParameters()
        })

        onUnmounted(() => {
            clearInterval(rotationInterval)
        })

        return {
            showcases,
            currentShowcaseIndex,
            switchToRegister,
            switchToForgotStart,
            switchToLogin,
            leftPanelX,
            rightPanelX,
            showRegisterForm,
            showForgotStartForm,
            handleRegisterComplete,
            loginFormRef,
            resetLoginErrors,
            handleForgotPassword,
            registerFormRef,
            handleBackFromReset,
            didResetPassword,
            resetUserData,
            handleLogin,
            isLoading,
            errorMessage,
            emailWarning,
            passwordWarning,
            showWelcome,
            orgID,
            handleUrlParameters,
            showForceResetForm,
            switchToForceResetForm,
            userId,
            switchToChooseMultipleOrgs,
            showMultipleOrgs
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
    transition: opacity 0.4s ease;
}

.fade-slow-enter-from,
.fade-slow-leave-to {
    opacity: 0;
}

/* Add smooth scrolling to the whole page */
html {
    scroll-behavior: smooth;
}

/* Custom scrollbar for webkit browsers */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import useMainStore  from '@/stores/main'
import LogRocket from 'logrocket';

const OrganizationView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Instructor/OrganizationView.vue");
const RepsCourseView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/RepsCourseView.vue");
const ForgotPasswordView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Login/ForgotPasswordView.vue");
const PasswordAdminResetView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Instructor/PasswordAdminResetView.vue");
const InteractiveViewContentView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Interactive/InteractiveViewContentView.vue");
const LoginView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Login/LoginView.vue");
const RegisterOrganizationUser = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Register/RegisterOrganizationUserView.vue");

const PageNotFound = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Error/PageNotFoundView.vue");
const StoreView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Store/StoreView.vue");
const StoreDetailView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Store/StoreDetailView.vue");
import CheckoutCartView from '@/views/Store/CheckoutCartView.vue'
import { Dash } from '@vime/vue-next';
const ProfileView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Student/ProfileView.vue");
const JoinView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Archived/JoinView.vue");
const StudentGameView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/StudentGameView.vue");
const MultiCamView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Student/MultiCamView.vue");
const NewCourseView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Student/NewCourseView.vue");
const NewDashboardView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Student/NewDashboardView.vue");
const ChatView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/components/Instructor/MessagingUI/Chat.vue");
const StudentDashboardView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/StudentDashboard.vue");
const DragonFlyRegisterView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Register/DragonFlyRegisterView.vue");
const SummerCampsView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/SummerCamps/SummerCampsView.vue");
const MaintenanceView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/MaintenanceView.vue");
const LogsView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Logs.vue");
// const Q2DashboardView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Student/Q2DashboardView.vue");
const Q2DashboardView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Student/Q2DashboardView.vue");
const VTTEditorView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/VTTEditorView.vue");
const CourseEditor = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/components/CourseEditor.vue");
const AddOrganizationView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/AddOrganizationView.vue");
const RefRepsDashboardView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/DashboardView.vue");
const NYCourseView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Student/NYCourseView.vue");
const NYRegisterView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/views/Register/NYRegisterView.vue");
const PasswordResetView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/components/Login/PasswordReset.vue");
const InviteWelcome = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/components/Login/InviteWelcome.vue");
const RegisterForm = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/components/Login/Register/RegisterForm.vue");
const LearningActivityStudentView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-side" */ "@/components/Student/Dashboard/LearningActivityStudentView.vue");

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Q2DashboardView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      props: (route) => ({ query: route.query })
    },
    {
      path: '/dragonfly/register',
      name: 'dragonfly-register',
      component: DragonFlyRegisterView
    },
    {
      path: '/ny/register',
      name: 'ny-register',
      component: NYRegisterView
    },
    {
      path: '/summer-camps',
      name: 'summer-camps',
      component: SummerCampsView
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: ForgotPasswordView
    },
    {
      path: '/forgot/Du2CUEobIIrGnfn9QvxNWtJLnVqtsf0uTuVptWvasnkovKf80CtAz9O0fgYSsBGsbIfZCQDmE6n6H56sLJqJ4XMOGF8zkFeP1pp8djAhpTXVpeaYUwhI9PDTN9gjAKzeDWfPAJw7uxO1LDYMmakHqz/:email',
      name: 'forgot-admin',
      component: PasswordAdminResetView
    },
    {
      path: '/forgot/Du2CUEobIIrGnfn9QvxNWtJLnVqtsf0uTuVptWvasnkovKf80CtAz9O0fgYSsBGsbIfZCQDmE6n6H56sLJqJ4XMOGF8zkFeP1pp8djAhpTXVpeaYUwhI9PDTN9gjAKzeDWfPAJw7uxO1LDYMmakHqz',
      name: 'forgot-nm',
      component: PasswordAdminResetView
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterOrganizationUser
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Q2DashboardView
    },
    {
      path: '/new',
      name: 'newDashboard',
      component: StudentDashboardView
    },
    {
      path: '/explore',
      name: 'explore',
      component: StoreView
    },
    {
      path: '/explore/:id',
      name: 'course-detail',
      component: StoreDetailView
    },
    {
      path: '/game',
      name: 'game',
      component: StudentGameView
    },
    {
      path: '/explore/discount/:discount/:id',
      name: 'course-detail-discount',
      component: StoreDetailView
    },
    {
      path: '/checkout-cart',
      name: 'checkout-cart',
      component: CheckoutCartView
    },
    {
      path: '/course/:id',
      name: 'course',
      component: NewCourseView
    },
    {
      path: '/course/ny/:id',
      name: 'ny-course',
      component: NYCourseView
    },
    {
      path: '/newCourse/:id',
      name: 'newCourse',
      component: NewCourseView
    },
    {
      path: '/reps/:id',
      name: 'reps',
      component: RepsCourseView
    },
    {
      path: '/interactive/view/:id',
      name: 'interactive-view',
      component: InteractiveViewContentView
    },
    {
      path: '/organization/:id',
      name: 'organization',
      component: OrganizationView
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfileView
    },
    {
      path: '/join/:id',
      name: 'join',
      component: JoinView
    },
    {
      path: '/purchase/cancel',
      name: 'purchase-cancel',
      component: CheckoutCartView
    },
    {
      path: '/multicam',
      name: 'multicam',
      component: MultiCamView
    },
    {
      path: '/messages',
      name: 'messages',
      component: ChatView
    },
    {
      path: '/vtt-editor',
      name: 'vttEditor',
      component: VTTEditorView
    },
    {
      path: '/course-editor',
      name: 'course-editor',
      component: CourseEditor
    },
    {
      path: '/add-organization',
      name: 'add-organization',
      component: AddOrganizationView
    },
    {
      path: '/refreps-dashboard',
      name: 'refreps-dashboard',
      component: RefRepsDashboardView
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: PasswordResetView
    },
    {
      path: '/invite',
      name: 'invite',
      component: InviteWelcome,
      props: (route) => ({ 
        id: route.query.id,
        isLoggedIn: route.query.isLoggedIn === 'true'
      })
    },
    {
      path: '/register/:id',
      name: 'register',
      component: RegisterForm
    },
    {
      path: "/:catchAll(.*)",
      name: "not-found",
      component: PageNotFound
    },    
    {
      path: '/learning-activity',
      name: 'learning-activity',
      component: LearningActivityStudentView
    }
    
  ],
  linkExactActiveClass: 'text-teal-400 font-semibold',
})


router.beforeEach((to, from, next) => {

  LogRocket.log(`Navigating from ${from.fullPath} to ${to.fullPath}`);

  try {
    const mainStore = useMainStore()
    
    // Check if the user is an instructor and trying to access the root path
    if (to.path === '/' && mainStore.isUserInstructor) {
      // Redirect to the organization route
      // Note: You might need to adjust this if you need a specific organization ID
      next({ name: 'organization', params: { id: 'default' } })
    } else {
      // For all other cases, proceed as normal
      next()
    }
  } catch (error) {
    console.error('Error in beforeEach guard:', error)
    next(error)
  }
})

export default router
